import { useState, useEffect } from "react";

export function useFetch(url, {method, enabled, onSuccess, body, headers}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (enabled) {
      const controller = new AbortController();
      const signal = controller.signal;
  
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await fetch(url, {signal, method, body: JSON.stringify(body), headers: headers});
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const result = await response.json();
          setData(result);
          if (onSuccess) {
            onSuccess(result)
          }
        } catch (error) {
          if (error.name !== 'AbortError') {
            setError(error);
          }
        } finally {
          if (!signal.aborted) {
            setLoading(false);
          }
        }
      };
  
      fetchData();
  
      return () => {
        controller.abort();
      };
    }
  }, [url, enabled]);

  return { data, loading, error };
}