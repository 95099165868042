import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useRef,
  lazy,
} from 'react'
import classnames from 'classnames'
import {useMediaQuery} from 'react-responsive'
import {useHistory, useLocation} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import queryString from 'query-string'

import {
  SLIDES,
  ND_SLIDES,
  LIST_ONE,
  LIST_TWO,
  INFO_BLOCKS_DATA,
  NUMBERS_REGEX,
  MAX_ZIP_INPUT_LENGTH,
} from 'consts'

import Car from 'assets/car.png'
import CarMobile from 'assets/car-mobile.png'
import CarTablet from 'assets/car-tablet.png'
import AI from 'assets/AI@3x.png'
import AI_webp from 'assets/AI@3x.webp'
import Servers from 'assets/servers@3x.png'
import ServersWebp from 'assets/servers@3x.webp'
import {Button} from 'components/Button/Button'
import {Carousel} from 'components/Carousel/Carousel'
import {InfoBlocks} from 'components/InfoBlocks/InfoBlocks'
import {BulletList} from 'components/BulletList/BulletList'
import Companies from './Companies'
import ZipInput from 'components/Input/ZipInput/ZipInput'

import styles from './styles.module.scss'
import {LeadService} from 'services/LeadService'
import {useOnce} from 'hooks/useOnce'
import {useCookies} from 'react-cookie'
import OneClick from 'views/OneClick/OneClick'

const Form = lazy(() => import('views/Form/FormWrapper'))
const isIE = /*@cc_on!@*/ false || !!document.documentMode

const EMPTY_GUID = '00000000-0000-0000-0000-000000000000'

export default function Landing(props) {
  const isMobile = useMediaQuery({query: '(max-width: 500px)'})
  const isDesktop = useMediaQuery({query: '(min-width: 1025px)'})
  let history = useHistory()
  let location = useLocation()

  const {isLoadingOneClick, oneClickData} = props
  const [isOneClickEdit, setIsOneClickEdit] = useState(false)
  const [isIncorrectInput, setIsIncorrectInput] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false)
  const [validationSuccessed, setValidationSuccessed] = useState(false)
  const [validationFinished, setValidationFinished] = useState(false)
  const [formConfigLoaded, setFormConfigLoaded] = useState(false)
  const [makesLoaded, setMakesLoaded] = useState(false)
  const [zipValue, setZipValue] = useState('')
  const [showLoading, setShowLoading] = useState(false)
  const [stateValue, setStateValue] = useState('')
  const [cityValue, setCityValue] = useState('')
  const [formConfig, setFormConfig] = useState(null)
  const [makes, setMakes] = useState(null)
  const [urlParams, setUrlParams] = useState(null)
  const [cookies, setCookie] = useCookies()
  const [titlesData, setTitlesData] = useState(null)
  const mainHeadline = useRef(null)
  const [isABVarient, setIsABVarient] = useState(false)
  const {isShort} = props
  let queryStringParams = queryString.parse(location.search)
  const isNewDesign = isShort
  const isV3 = queryStringParams['v'] === '3'
  const isFcc = queryStringParams['cx_fcc'] === '2' || cookies.cx_fcc === '2'

  let buttonLabel = 'Get Started'
  if (
    queryStringParams['cmp'] === 'qs' ||
    queryStringParams['cmp'] === 'ma' ||
    queryStringParams['cmp'] === 'ue'
  )
    buttonLabel = 'Continue'

  const getLeadId = () => {
    let urlParameters = queryString.parse(location.search)
    return urlParameters['leadId'] || cookies['leadId']
  }

  const getParametersFromUrl = queryStringParams => {
    let result = {}
    let odParams = [
      'eid',
      'clickid',
      'utm_source',
      'utm_medium',
      'utm_term',
      'utm_campaign',
      's',
      's1',
      's2',
      'device',
      'adposition',
      'gclid',
      'ref',
      'ijsbid',
      'emailtracking',
    ]
    for (let key in queryStringParams) {
      let value = encodeURIComponent(queryStringParams[key])
      key = key.toLowerCase()
      if (odParams.indexOf(key) >= 0) result['od_' + key] = value
      if (key.indexOf('cx_') === 0) result[key] = value
      if (key == 'edu') result['education'] = value
      if (key == 'credrate') result['creditScore'] = value
    }
    // set some defaults if not exist
    if (!result['od_s'] && !cookies['od_s']) result['od_s'] = '200'
    if (!result['od_utm_source'] && !cookies['od_utm_source'])
      result['od_utm_source'] = 'organic_sureint'
    result['od_referrer'] = document.referrer
    result['od_localreferer'] = window.location.href
    return result
  }

  const saveObjectToCookies = obj => {
    for (let key in obj) {
      setCookie(key, obj[key])
    }
  }

  const onLeadCreateCallback = useCallback(
    (response, formData) => {
      if (response.success && response.leadId) {
        let dateNow = Date.now()
        let newSearch = queryString.parse(window.location.search)
        newSearch['leadId'] = response.leadId
        newSearch['ldc'] = dateNow
        setCookie('ldc', dateNow)
        history.replace({
          pathname: window.location.pathname,
          search: queryString.stringify(newSearch),
          formConfig: formConfig,
          makes: makes,
          leadId: response.leadId,
          formData: formData,
        })
      }
    },
    [location],
  )

  const afterGetStateResult = () => {
    document.body.classList.remove(styles.hidden)
    // Dynamically change font size until reached two lines
    if (mainHeadline.current) {
      let lineHeight = Number(
        document.defaultView
          .getComputedStyle(mainHeadline.current)
          .lineHeight.replace('px', ''),
      )
      let linesCount = Math.ceil(mainHeadline.current.offsetHeight / lineHeight)
      if (linesCount >= 3) {
        let fontSize = Math.round(
          Number(
            document.defaultView
              .getComputedStyle(mainHeadline.current, null)
              .fontSize.replace('px', ''),
          ),
        )
        while (linesCount >= 3) {
          fontSize -= 1
          mainHeadline.current.style.fontSize = fontSize + 'px'
          linesCount = Math.ceil(mainHeadline.current.offsetHeight / lineHeight)
          lineHeight = Number(
            document.defaultView
              .getComputedStyle(mainHeadline.current)
              .lineHeight.replace('px', ''),
          )
        }
      } else {
        let fontSize = Math.round(
          Number(
            document.defaultView
              .getComputedStyle(mainHeadline.current, null)
              .fontSize.replace('px', ''),
          ),
        )
        while (linesCount <= 2) {
          fontSize += 1
          mainHeadline.current.style.fontSize = fontSize + 'px'
          lineHeight = Number(
            document.defaultView
              .getComputedStyle(mainHeadline.current)
              .lineHeight.replace('px', ''),
          )
          linesCount = Math.round(
            mainHeadline.current.offsetHeight / lineHeight,
          )
        }
        fontSize -= 1
        mainHeadline.current.style.fontSize = fontSize + 'px'
      }
    }
    document.body.classList.remove(styles.transparent)
  }

  const isValidZipCode = zip => {
    return /^\d{5,5}$/.test(zip.trim())
  }

  useOnce(() => {
    if (isShort) {
      window.getTitles
        .then(data => {
          if (data) {
            setTitlesData(data)
          }
          afterGetStateResult()
        })
        .catch(err => {
          console.error('error on get state:', err)
          afterGetStateResult()
        })
    }
    let queryStringParams = queryString.parse(location.search)
    let urlParams = getParametersFromUrl(queryStringParams)
    if (queryStringParams['zip'] && isValidZipCode(queryStringParams['zip']))
      handleOnChange({target: {value: queryStringParams['zip']}})
    if (
      (queryStringParams['cx_abvarient'] == '1' ||
        queryStringParams['cx_abvarient'] == '2' ||
        queryStringParams['cx_abvarient'] == '3') &&
      queryStringParams['abvisited'] != '1' &&
      cookies['abvisited'] != '1'
    ) {
      setIsABVarient(true)
      createLeadWithRetries({
        localTime: getUserCurrentTime(),
        ...urlParams,
        zip: '',
        city: '',
        state: '',
        status: 'Visit',
      })
      setCookie('abvisited', '1')
      let newSearch = queryString.parse(location.search)
      newSearch['abvisited'] = '1'
      history.replace({
        pathname: window.location.pathname,
        search: queryString.stringify(newSearch),
      })
    }
    saveObjectToCookies(urlParams)
    setUrlParams(urlParams)

    let promise = window.getFormConfig
    if (!window.getFormConfig) {
      promise = fetch(`/data/getFormConfig`, {
        method: 'POST',
        body: JSON.stringify({
          isShort: isShort ?? false,
          url: window.location.href,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error('Network response was not ok.')
      })
    }

    promise.then(responseFormConfig => {
      Form._ctor()
      setFormConfig(responseFormConfig)
      setFormConfigLoaded(true)
      window.getFormConfig = null
    })
    fetch('/data/getCarMakers')
      .then(res => res.json())
      .then(data => {
        setMakes(data)
        setMakesLoaded(true)
      })
  })

  const getUserCurrentTime = () => {
    try {
      return new Date().toString().split('GMT')[0]
    } catch (err) {
      console.error('error getting user local time:', err)
    }
  }

  useEffect(() => {
    if (
      submitClicked &&
      validationSuccessed &&
      formConfigLoaded &&
      makesLoaded
    ) {
      let formData = {
        zip: zipValue,
        city: cityValue,
        state: stateValue,
        localTime: getUserCurrentTime(),
        ...urlParams,
      }

      if (!isABVarient) createLeadWithRetries(formData)
      else LeadService.updateLead({...formData, leadId: getLeadId()}, isFcc)
      let newSearch = queryString.parse(window.location.search)
      if (isShort) newSearch['sf'] = '1'
      history.push({
        pathname: '/form',
        search: queryString.stringify(newSearch),
        formConfig: formConfig,
        makes: makes,
        formData: formData,
        isShort: isShort,
      })
    }
  }, [
    submitClicked,
    validationSuccessed,
    formConfigLoaded,
    makesLoaded,
    formConfig,
    makes,
    history,
  ])

  const checkLeadCreateResponse = response => {
    return new Promise((resolve, reject) => {
      if (
        response &&
        response.success &&
        response.leadId &&
        response.leadId !== EMPTY_GUID
      )
        resolve([true, response])
      else if (response) {
        console.warn('Unable to create lead, retrying')
        setTimeout(resolve.bind(null, [false, null]), 1500)
      }
    })
  }

  const checkFinalResult = result => {
    return new Promise((resolve, reject) => {
      if (result[0]) resolve(result[1])
      else reject('Unable to create lead after maximum tries')
    })
  }

  const tryCreateLead = (tryNum, result, leadData) => {
    if (!result[0]) {
      if (tryNum > 0) leadData['cx_fallbackcookie'] = 'retry-' + tryNum
      return LeadService.createEmptyLead(leadData, isFcc).then(res => res.json())
    }
    return result[1]
  }

  const createLeadWithRetries = leadData => {
    // Make sure we dont have a ldc parameter or it has not run the timeout
    let queryParams = queryString.parse(location.search)
    let ldc = queryParams['ldc'] || cookies['ldc']
    if (ldc) {
      let minutesElapsed = (Date.now() - ldc) / 1000 / 60
      if (minutesElapsed < 60) return
    }
    // Retry maximum 5 times with 1500ms delay to create a new lead
    let maxRetries = 5
    let p = Promise.resolve([false, null])
    for (let i = 0; i < maxRetries; i++) {
      p = p
        .then(result => tryCreateLead(i, result, leadData))
        .then(checkLeadCreateResponse)
    }
    return p
      .then(checkFinalResult)
      .then(response => onLeadCreateCallback(response, leadData))
      .catch(err => console.error('error creating lead', err))
  }

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      const correctZipInput =
        zipValue.length === MAX_ZIP_INPUT_LENGTH && NUMBERS_REGEX.test(zipValue)
      setSubmitClicked(true)

      if (correctZipInput) {
        if (validationFinished && formConfigLoaded && makesLoaded) {
          setShowLoading(false)
        } else {
          setShowLoading(true)
        }
      } else {
        setIsIncorrectInput(true)
      }
    },
    [validationFinished, formConfigLoaded, makesLoaded, zipValue],
  )

  const handleOnChange = useCallback(event => {
    const value = event.target.value
    setIsIncorrectInput(false)
    setZipValue(value)
    setSubmitClicked(false)
    setValidationSuccessed(false)

    if (value.length === MAX_ZIP_INPUT_LENGTH) {
      if (NUMBERS_REGEX.test(value)) {
        setValidationFinished(false)
        fetch('/validation/zip?zipcode=' + value)
          .then(res => res.json())
          .then(result => {
            setShowLoading(false)
            setValidationFinished(true)
            if (result.valid) {
              setValidationSuccessed(true)
              setCityValue(result.city)
              setStateValue(result.state)
            } else {
              setValidationSuccessed(false)
              setIsIncorrectInput(true)
            }
          })
      } else {
        setIsIncorrectInput(true)
      }
    }
  }, [])

  const createEmptyLeadOneClickData = {
    zip: zipValue,
    city: cityValue,
    state: stateValue,
    localTime: getUserCurrentTime(),
    ...urlParams,
  }
  if (oneClickData && !isOneClickEdit)
    return (
      <OneClick
        isShort={isShort}
        emptyLeadData={createEmptyLeadOneClickData}
        data={oneClickData}
        onEditOneClick={setIsOneClickEdit}
      />
    )

  return (
    <>
      {isLoadingOneClick && (
        <div className={styles.landingFade}>
          <div className={styles.loader} />
        </div>
      )}
      <div
        className={classnames(styles.LandingWrapper, {
          [styles.NewDesign]: isNewDesign,
        })}
      >
        {isNewDesign && (
          <Helmet>
            <title>
              {titlesData?.seo_title
                ? titlesData.seo_title
                : 'Auto Insurance. Affordable Quotes. | Sureint'}
            </title>
            <meta
              name="description"
              content={
                titlesData?.seo_description
                  ? titlesData.seo_description
                  : 'Our fast & free service for affordable car insurance quotes will help you find the cheapest rates while maintaining maximum coverage for all drivers.'
              }
            />
            <meta
              name="keywords"
              content={
                titlesData?.seo_keywords
                  ? titlesData.seo_keywords
                  : 'cheap auto insurance,cheap car insurance,car insurance,auto insurance,best car insurance,best auto insurance,cheap insurance,vehicle insurance,affordable insurance,insurance rates,insurance quotes,online rates,temporary insurance,gap insurance,liability,collision,sr22 insurance,sr-22 insurance,dui insurance,high risk drivers,no deposit insurance,down payment insurance,young drivers,senior drivers,teen drivers,sureint,sureint.com'
              }
            />
          </Helmet>
        )}
        <form className={styles.FlexWrapper} onSubmit={handleSubmit}>
          <h1
            className={classnames({
              [styles.Title]: true,
              [styles.Home]: !isShort,
            })}
            ref={mainHeadline}
          >
            {isShort ? (
              titlesData?.title ? (
                titlesData.title
              ) : titlesData?.state ? (
                isV3 ? (
                  <>
                    Let’s Drop Your Auto Insurance
                    <br /> Rates In {titlesData.state}!
                  </>
                ) : (
                  <>
                    Get Cheaper Auto Insurance
                    <br /> Rates In {titlesData.state}!
                  </>
                )
              ) : isV3 ? (
                <>Let’s Drop Your Auto Insurance Rates Today!</>
              ) : (
                <>Get Cheaper Auto Insurance Rates Today!</>
              )
            ) : (
              <>
                Compare Auto Insurance<span>.</span>
                <br /> The Intelligent Way<span>.</span>
              </>
            )}
          </h1>
          {isNewDesign ? (
            <span className={styles.EnterZip}>Enter Your Zip Code:</span>
          ) : (
            <div className={styles.ArrowDown} />
          )}
          <ZipInput
            id="zip-code"
            name="zip"
            placeholder="Zip Code"
            pattern="[0-9]*"
            maxlength={MAX_ZIP_INPUT_LENGTH}
            type="text"
            isIncorrect={isIncorrectInput}
            onChange={handleOnChange}
            value={zipValue}
            inputMode="numeric"
            className={styles.ZipInput}
            isNewDesign={isNewDesign}
          />
          <Button
            className={styles.Button}
            label={buttonLabel}
            withArrow
            type="submit"
            isLoading={showLoading}
            isNewDesign={isNewDesign}
          />
          <Companies />
          {!isNewDesign && (
            <img
              className={styles.Car}
              src={isDesktop ? Car : isMobile ? CarMobile : CarTablet}
              alt="Car"
            />
          )}
          {isNewDesign && isDesktop && (
            <div className={styles.DesktopFormBottom}></div>
          )}
        </form>
        <Carousel
          imgClassName={styles.SlideImage}
          slides={isNewDesign ? ND_SLIDES : SLIDES}
        />
        <hr className={styles.Seperator} />
        <div
          className={classnames(
            styles.FlexWrapper,
            styles.FlexWrapper__colored,
          )}
        >
          {isDesktop && !isNewDesign && (
            <Fragment>
              <div className={classnames(styles.Road, styles.Road__one)} />
              <div className={classnames(styles.Road, styles.Road__two)} />
              <div className={classnames(styles.Road, styles.Road__three)} />
            </Fragment>
          )}
          {isNewDesign ? (
            <>
              <div className={styles.FlexWrapper}>
                <h2 className={styles.SectionTitle}>
                  {titlesData?.sub_title
                    ? titlesData.sub_title
                    : 'How To Get Affordable Car Insurance And Find Cheapest Rates Online?'}
                </h2>
                <p className={styles.SectionText}>
                  Think about all the complex algorithms insurance companies are
                  using to determine your quotes. We are using similar data
                  capabilities to make that information accessible to you as a
                  consumer.
                </p>
                <div className={styles.ListOneWrapper}>
                  <BulletList list={LIST_ONE} />
                </div>
                <h2 className={styles.SectionTitle}>
                  {titlesData?.paragraph_title
                    ? titlesData.paragraph_title
                    : 'Your Kind of Insurance'}
                </h2>
                <p className={styles.SectionText}>
                  Your insurance needs vary greatly. Are you looking for
                  liability insurance? Maybe gap insurance? A policy with no
                  down payment or no deposit? If you are not familiar with these
                  terms you should read a little about the differences before
                  committing to any policy.
                </p>
                <p className={styles.SectionText}>
                  Finding affordable car insurance in an intelligent way has
                  never been easier. Looking for cheap quotes does not mean you
                  are willing to compromise on full coverage. Every driver can
                  find the right insurance carrier, it’s just a matter of
                  knowing where to look.
                </p>
              </div>
              <div
                className={classnames(
                  styles.ImageWithNotation,
                  styles.ImageWithNotation__ai,
                )}
              >
                <picture>
                  <source srcSet={AI_webp} type="image/webp" />
                  <img
                    loading="lazy"
                    className={styles.Image}
                    src={AI}
                    alt="AI"
                  />
                </picture>
                <div
                  className={classnames(styles.Notation, styles.Notation__ai, {
                    [styles.Notation__isIE]: isIE,
                  })}
                >
                  <h3 className={styles.NotationTitle}>Did You Know?</h3>
                  <p className={styles.NotationSubtitle}>
                    Comparing between multiple providers is one of the best ways
                    to save money on your auto insurance (especially if you have
                    tickets, DUI or SR22).
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.FlexWrapper}>
                <h2 className={styles.SectionTitle}>
                  How Machine Learning Can Help You Save
                </h2>
                <p className={styles.SectionText}>
                  Think about all the complex algorithms insurance companies are
                  using to determine the cost of your monthly premiums. We are
                  using similar data capabilities to make that information
                  accessible to you as a consumer.
                </p>
                <div className={styles.ListOneWrapper}>
                  <BulletList list={LIST_ONE} />
                </div>
              </div>
              <div
                className={classnames(
                  styles.ImageWithNotation,
                  styles.ImageWithNotation__ai,
                )}
              >
                <picture>
                  <source srcSet={AI_webp} type="image/webp" />
                  <img
                    loading="lazy"
                    className={styles.Image}
                    src={AI}
                    alt="AI"
                  />
                </picture>
                <div
                  className={classnames(styles.Notation, styles.Notation__ai, {
                    [styles.Notation__isIE]: isIE,
                  })}
                >
                  <h3 className={styles.NotationTitle}>Did You Know?</h3>
                  <p className={styles.NotationSubtitle}>
                    Comparing between multiple providers is one of the best ways
                    to save money on your auto insurance.
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        {!isNewDesign && (
          <div className={styles.FlexWrapper}>
            <div className={styles.InfoBlocksWrapper}>
              <InfoBlocks info={INFO_BLOCKS_DATA} />
            </div>
            <div
              className={classnames(
                styles.FlexWrapper,
                styles.FlexWrapper__servers,
              )}
            >
              <div className={styles.FlexWrapper}>
                <h2 className={styles.SectionTitle}>
                  Information Becomes An Ability To Save
                </h2>
                <p className={styles.SectionText}>
                  Sureint’s proprietary technology takes the information you
                  have provided and uses it to check thousands of policies from
                  different insurance providers and find an intelligent match.
                  An intelligent match will occur when the information you have
                  provided fits the actuary restrictions of an insurance
                  provider and they are able to insure you at a good price.
                </p>
                <h3 className={styles.Text}>
                  All the results you will receive using our comparison platform
                  are companies you were intelligently matched with.
                </h3>
              </div>
              <div
                className={classnames(
                  styles.ImageWithNotation,
                  styles.ImageWithNotation__servers,
                )}
              >
                <picture>
                  <source srcSet={ServersWebp} type="image/webp" />
                  <source srcSet={Servers} type="image/png" />
                  <img src={Servers} alt="Servers" className={styles.Image} />
                </picture>
                <div
                  className={classnames(
                    styles.Notation,
                    styles.Notation__servers,
                    {[styles.Notation__isIE]: isIE},
                  )}
                >
                  <h3 className={styles.NotationTitle}>Did You Know?</h3>
                  <p className={styles.NotationSubtitle}>
                    The Sureint platform can find you a policy even if you are
                    not currently insured.
                  </p>
                </div>
              </div>
            </div>
            <h2
              className={classnames(
                styles.SectionTitle,
                styles.SectionTitle__centered,
              )}
            >
              Save Money The Intelligent Way
            </h2>
            <p
              className={classnames(
                styles.SectionText,
                styles.SectionText__centered,
              )}
            >
              When shopping around for an insurance policy, price is definitely
              something to take into consideration. But, there are many other
              things you should be aware of that might be even more important.
              The type and amount of coverage you will receive from a policy
              should be the dominant factor when assessing the best policy
              purchase.
            </p>
            <div
              className={classnames(
                styles.FlexWrapper,
                styles.FlexWrapper__lastSection,
              )}
            >
              <div className={styles.InfoSectionTwo}>
                <h3 className={styles.ColorText}>
                  Insurance That Changes With You
                </h3>
                <p className={styles.SectionText}>
                  We take into consideration many pieces of information you
                  provide when deciding what insurance options to show you.
                  These options will be constructed by our algorithm to answer
                  as many of your needs as possible.
                  <br />
                  <br />
                  As your circumstances change, please feel free to recheck your
                  insurance options for a policy that fits you better.
                </p>
              </div>
              <div className={styles.InfoSectionTwo}>
                <h3 className={styles.ColorText}>
                  Has There Been a Change in:
                </h3>
                <BulletList list={LIST_TWO} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
