import React, { Fragment, useCallback } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

export const Button = ({
  label,
  onClick,
  className,
  withArrow,
  isDisabled = false,
  type = "button",
  isLoading,
  isNewDesign = false,
  disabledStyle = true,
  onDisabledClick = null,  
}) => {
 const onClickHandler = useCallback(() => {
  if(!isDisabled && onClick)
    onClick();
  else if(onDisabledClick)
    onDisabledClick();
 }, [isDisabled, onClick, onDisabledClick]);

  return (
    <button
      onClick={() => onClickHandler()}
      className={classnames(
        styles.Button,
        className,
        { [styles.Button__isLoading]: isLoading },
        { [styles.Button__newDesign]: isNewDesign }
      )}
      type={type}
      disabled={disabledStyle && isDisabled}
    >
      {isLoading
        ? (
          <div className={styles.Spinner} />
        )
        : (
          <Fragment>
            <span className={styles.Label}>{label}</span>
            {withArrow && (
              <div className={styles.Arrow} />
            )}
          </Fragment>
        )
      }
    </button>
  );
}
