import React, {Fragment, useState, useEffect, useRef} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import IconEdit from 'assets/icon-edit.svg'
import IconCar from 'assets/icon-car.svg'
import IconHouse from 'assets/icon-house.svg'
import IconPhone from 'assets/icon-phone.svg'
import IconLocation from 'assets/icon-location.svg'
import IconShield from 'assets/icon-shield.svg'
import Banner1 from 'assets/banner1.svg'
import Banner2 from 'assets/banner2.svg'
import Banner3 from 'assets/banner3.svg'
import PhoneOneClick from 'assets/phone-oneclick.png'
import GreenArrow from 'assets/icon-arrow-right-green.svg'
import Arrow from 'assets/arrow-back-white.svg'
import queryString from 'query-string'
import {Button} from 'components/Button/Button'
import Checkbox from 'components/Checkbox/Checkbox'
import {useCookies} from 'react-cookie'
import useTrustedFormAndLeadId from '../../hooks/useTrustedFormAndLeadId'
import {useFetch} from 'hooks/useFetch'
import {LeadService} from 'services/LeadService'
import classNames from 'classnames'
import styles from './oneclick.module.scss'

export default function OneClick({
  data,
  onEditOneClick,
  emptyLeadData,
  isShort,
}) {
  const location = useLocation()
  const history = useHistory()
  const [cookies, setCookie, removeCookie] = useCookies()
  const [loading, setShowLoading] = useState(false)
  const queryStringParams = queryString.parse(window.location.search)
  const isFcc = queryStringParams['cx_fcc'] === '1' || cookies.cx_fcc === '1'

  const tcpa = useRef(null)
  const [form, setForm] = useState({
    ...data.form,
    phone: queryStringParams['phone'],
  })

  const {data: companies, loading: isLoadingCompanies} = useFetch(
    `/lead/ping?leadId=${LeadService.getLeadId()}`,
    {
      method: 'POST',
      enabled: isFcc && !loading,
      onSuccess: data => {
        setForm({
          ...form,
          companies: data,
        })
      },
    },
  )

  const {
    phone,
    address,
    cars,
    car,
    isInsured,
    zipCode,
    fullName,
    firstName,
    isHomeOwner,
  } = data
  const otherCars = cars.slice(1)

  useTrustedFormAndLeadId()

  const onSetEditOneClick = () => {
    window.location.href =
      window.location.origin +
      location.pathname +
      location.search +
      '&v=one-click-edit'
    history.push({
      pathname: isShort ? '/auto-insurance' : '/',
      search: location.search + '&v=one-click-edit',
    })
  }

  const onDeleteOneClick = () => {
    window.location.href =
      window.location.origin +
      location.pathname +
      '?s2=one-click-full-data-reseted'
  }

  const handleCompaniesChange = (e, label) => {
    const checked = e.target.checked
    const isCurrentInList = form.companies.includes(label)
    let newCompanies = form.companies
    if (checked) {
      newCompanies = [...newCompanies, label]
    } else {
      newCompanies = newCompanies.filter(x => x !== label)
    }

    setForm({
      ...form,
      companies: newCompanies,
    })
  }

  const handleSubmitButtonClick = async () => {
    setShowLoading(true)
    let newSearch = queryString.parse(location.search)
    delete newSearch['ldc']
    removeCookie('ldc');

    const trustedFields = {};
    if (document.getElementById('leadid_token')?.value)
      trustedFields['jornaya'] = document.getElementById('leadid_token').value
    if (document.querySelector('[name=xxTrustedFormToken]')?.value)
      trustedFields['trustedform'] = document.querySelector(
        '[name=xxTrustedFormToken]',
      ).value
    if (document.getElementById('mediaalpha-certificate-id')?.value)
      trustedFields['macertid'] = document.getElementById(
        'mediaalpha-certificate-id',
      ).value

    const response = await LeadService.createEmptyLead({
      ...emptyLeadData,
      status: 'one-click',
    }).then(res => res.json())
    if (response.leadId !== '00000000-0000-0000-0000-000000000000') {
      newSearch['leadId'] = response.leadId
      setCookie('leadId', response.leadId)
    }

    const finalForm = {
      ...form,
      ...emptyLeadData,
      ...trustedFields,
      tcpa: tcpa.current.innerText,
    }

    if (window.LeadiD) window.LeadiD.snap()
    LeadService.saveData(finalForm)
      .then(res => res.text())
      .then(response => {
        if (response !== 'error') newSearch.leadId = response
        history.push({
          pathname: '/results',
          firstName: firstName,
          search: queryString.stringify(newSearch),
        })
      })
      .catch(() => {
        setShowLoading(false)
      })
    if (isFcc) {
      LeadService.post(form)
    }
  }

  const renderCompanies = () => {
    if (!isFcc) return null
    return (
      <div className={styles.fcc_comp}>
        <div className={styles.fcc_title}>
          You agree to be contacted by each of the companies checked below:
        </div>
        {isLoadingCompanies ? (
          <span className={styles.loader} />
        ) : (
          <div className={styles.fcc_checkboxes}>
            {companies?.map(x => (
              <Checkbox
                key={x}
                checked={form.companies?.indexOf(x) !== -1}
                name="companies"
                value={x}
                label={x}
                onChange={e => handleCompaniesChange(e, x)}
              />
            ))}
          </div>
        )}
      </div>
    )
  }

  return (
    <Fragment>
      <div className={styles['main-container']}>
        <form method="get">
          <div
            id="inner-page"
            className={`${styles.container} ${styles['top-container']}`}
          >
            <div className={styles['section-title-main']}>
              <h2>Let’s Drop Your Auto Insurance Rates Today!</h2>
            </div>
            <div
              className={classNames(
                styles['main-cont'],
                styles['main-summary'],
              )}
            >
              <div className={styles['main-person-details']}>
                <a id="edit-info" onClick={onSetEditOneClick}>
                  <img src={IconEdit} alt="Edit Info" />
                  <span>Edit Info</span>
                </a>
                <h3 id="person-name">{fullName}</h3>
                <div className={styles['person-detail']}>
                  <img src={IconCar} alt="Car" />
                  <span>{car}</span>
                  {otherCars.length > 0 && (
                    <>
                      <span
                        className={classNames(
                          styles['more-cars'],
                          styles.tooltip,
                        )}
                      >
                        +{otherCars.length} More
                      </span>
                      <ul className={styles['tooltip-content']}>
                        {otherCars.map(({year, make, model}, i) => (
                          <li key={i}>
                            {year} {make} {model}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
                <div className={styles['person-detail']}>
                  <img src={IconPhone} alt="Phone" />
                  <span>{phone}</span>
                </div>
                <div className={styles['person-detail']}>
                  <img src={IconLocation} alt="Location" />
                  <span
                    className={classNames(
                      styles['address-text'],
                      styles.ellipsis,
                    )}
                  >
                    {address}
                  </span>
                  <span
                    className={classNames(
                      styles['tooltip-content'],
                      styles.address,
                    )}
                  >
                    {address}
                  </span>
                </div>
                <div className={styles['person-detail']}>
                  <img src={IconShield} alt="Shield" />
                  <span>Currently {isInsured ? 'Insured' : 'Not Insured'}</span>
                </div>
                <div className={styles['person-detail']}>
                  <img src={IconHouse} alt="Home" />
                  <span>{isHomeOwner ? 'Homeowner' : 'Renter'}</span>
                </div>
                <div className={styles['change-person']}>
                  <span>Not {firstName}?</span>
                  <a onClick={onDeleteOneClick}>Click Here</a>
                </div>
              </div>
            </div>
            {!isFcc && (
              <span className={styles['agree-statement']}>
                I confirm my information and agree to the consent below:
              </span>
            )}
            {renderCompanies()}
            <Button
              id="get-quotes-button"
              title="Get Quotes"
              className={styles['submit-button']}
              isDisabled={isFcc ? isLoadingCompanies : false}
              withArrow
              onClick={handleSubmitButtonClick}
              isLoading={loading}
              label={
                isFcc && isLoadingCompanies
                  ? 'Searching For Quotes...'
                  : 'Get Quotes'
              }
            />
            <input type="hidden" id="leadid_tcpa_disclosure" />
            {isFcc ? (
              <label
                ref={tcpa}
                id="leadid_tcpa_disclosure_label"
                htmlFor="leadid_tcpa_disclosure"
                className={classNames('no-translate', styles['agree-content'])}
              >
                By clicking "Get Quotes", you are providing your electronic
                signature and giving your express written consent to receive
                marketing and informational communications regarding Auto and
                Home insurance via automatic telephone dialing system and
                artificial and pre-recorded calls, Text Messaging/SMS/MMS, and
                emails from our website{' '}
                <a href="https://Quotewest.com" target="_blank">
                  Quotewest.com
                </a>{' '}
                and/or one or more of the companies checked above at the phone
                number and/or email address you provide above, including your
                wireless number, if applicable, even if the number is on a
                corporate, state, or national Do Not Call list. Consent is not a
                condition to purchase services or products, and you may revoke
                your consent at any time. You request that this website provide
                your information to the companies selected so they can contact
                you about their services. Carrier Text Messaging/SMS/MMS and
                data rates may apply. By clicking "Get Quotes", and submitting
                this form, you attest that you are 18+ years of age and agree to
                the{' '}
                <a href="/privacy" target="_blank">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href="/terms" target="_blank">
                  Terms &amp; Conditions
                </a>
                , including the mandatory arbitration provision and className
                action waiver contained therein.
              </label>
            ) : (
              <label
                ref={tcpa}
                id="leadid_tcpa_disclosure_label"
                for="leadid_tcpa_disclosure"
                className={classNames('no-translate', styles['agree-content'])}
              >
                By clicking "Get Quotes", I am providing my electronic signature
                and giving my express written consent to receive marketing and
                informational communications regarding insurance and related
                products via Automatic Telephone Dialing System and artificial
                and pre-recorded calls, SMS/MMS, and emails from this website
                and/or one or more of its{' '}
                <a href="/g/partner-list" target="_blank">
                  marketing partners (listed here)
                </a>{' '}
                at the phone number and/or email address I provide, including
                wireless numbers, if applicable, even if the number is on a
                corporate, state, or national Do Not Call list. Consent is not a
                condition to purchase services or products, and I may revoke my
                consent at any time. I request you provide my information to
                these partners so I may obtain their services. Carrier SMS/MMS
                and data rates may apply. By clicking "Get Quotes", and
                submitting this form, I attest that I am 18+ years of age and
                agree to the{' '}
                <a href="/privacy" target="_blank">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href="/terms" target="_blank">
                  Terms &amp; Conditions
                </a>
                , including the mandatory arbitration provision and class action
                waiver contained therein.
              </label>
            )}
            <input
              id="leadid_token"
              name="universal_leadid"
              type="hidden"
              value=""
            />
            <script id="LeadiDscript" type="text/javascript"></script>
            <input
              id="trustedform_token"
              name="xxTrustedFormCertUrl"
              type="hidden"
              value=""
            />
            <noscript>
              <img
                src="http://api.trustedform.com/ns.gif"
                alt="TrustedForm ns"
              />
            </noscript>
            <noscript>
              <img
                src="//create.leadid.com/noscript.gif?lac=0e9278df-16a0-f150-f3b1-2dc939c79725&lck=db726670-d7c7-48a2-6a47-74cdbb3a56aa&snippet_version=2"
                alt="LeadID noscript"
              />
            </noscript>
          </div>
        </form>
      </div>
      <div className={classNames(styles.bottom__content, styles['info-bg'])}>
        <div className={styles.content}>
          <h3 className={styles['title-top']}>
            How To Start Saving On Your Car Insurance?
          </h3>
          <div className={styles.bottom__content__banners}>
            <div
              className={classNames(
                styles.bottom__content__banner,
                styles.banner1,
              )}
            >
              <img src={Banner1} alt="A Little Information" />
              <h4>A Little Information</h4>
              <p>
                Provide some basic information about yourself and your vehicle
                (This will allow us to generate customized personalized
                results).
              </p>
            </div>
            <img
              className={styles['arrow-icon']}
              src={GreenArrow}
              alt="Arrow Icon"
            />
            <div
              className={classNames(
                styles.bottom__content__banner,
                styles.banner2,
              )}
            >
              <img src={Banner2} alt="A Bit Of Magic" />
              <h4>A Bit Of Magic</h4>
              <p>
                Our Smart matching engine will sift through thousands of
                possible insurance policies and only present you with relevant
                and valid options.
              </p>
            </div>
            <img
              className={styles['arrow-icon']}
              src={GreenArrow}
              alt="Arrow Icon"
            />
            <div
              className={classNames(
                styles.bottom__content__banner,
                styles.banner3,
              )}
            >
              <img src={Banner3} alt="A Lot Of Savings" />
              <h4>A Lot Of Savings</h4>
              <p>
                You have the freedom to choose the specific policy you feel
                suits you the most from a list of matching policies we provide.
              </p>
            </div>
          </div>
        </div>
        <div
          className={styles.bottom__content__earth}
          style={{paddingBottom: '20px'}}
        >
          <div className={styles.container}>
            <img
              src={PhoneOneClick}
              width="439"
              height="433.3"
              alt="How it works"
            />
            <div className={styles['right-side']}>
              <div className={styles.content}>
                <h3>How Does Sureint Work?</h3>
                <p>
                  We take all the information you provide regarding yourself and
                  your vehicle and compare it in real time to our live database
                  connected to dozens of top insurance carriers. This results in
                  a list of policies that are specific to your personal
                  circumstances and are from carriers that are willing to insure
                  you.
                </p>
                <p>
                  Why is it so important to have personalized results? Because
                  many of us spend countless hours filling endless documentation
                  just to find out there is no relevant policy or getting
                  declined for insurance. The Sureint platform makes sure
                  you are presented with the top options in one place.
                </p>
                <p>
                  The service is free and you are never obligated to buy the
                  presented policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
